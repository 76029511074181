<template>
  <div class="container">
    <PersonalDataScreen v-if="!hasCode" />
    <CodeScreen v-if="hasCode" />
  </div>
</template>

<script>
import CodeScreen from "@/components/CodeScreen.vue";
import PersonalDataScreen from "@/components/PersonalDataScreen.vue";

export default {
  name: "HomeView",
  components: {
    CodeScreen,
    PersonalDataScreen,
  },
  computed: {
    hasCode() {
      let code = this.$store.getters.getCode;
      if (code !== null) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
body {
  background: url("~@/assets/bg_green_purple.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  padding-top: 2%;
}
.statsContainer {
  margin: auto;
  margin-bottom: 40px;
}
.statContainer {
  margin: auto;
  margin-bottom: 20px;
  font-size: 55px;
  font-weight: 200;
  color: white;
  background-color: #7c6192;
  width: 60%;
  padding: 20px;
  border-radius: 50px;
  box-shadow: 8px 8px grey;
  white-space: nowrap;
}
.statsContainer strong {
  font-weight: 600;
}
a {
  text-decoration: none;
}
</style>
