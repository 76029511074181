<template lang="html">
  <div class="kop">
    <img src="@/assets/kop.png" class="piekvanjut" alt="" />
    <h1>Your game code is:</h1>
    <h1 class="code">{{ getCode }}</h1>
  </div>
</template>

<script>
export default {
  name: "CodeScreen",
  data: () => ({
    code: null,
  }),
  computed: {
    getCode() {
      return this.$store.getters.getCode;
    },
  },
  methods: {},
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 40px;
  color: #333;
}
.kop {
  text-align: center;
}
img.piekvanjut {
  max-width: 800px;
  width: 100%;
}
.code {
  font-size: 60px;
  font-family: monospace;
}
</style>
