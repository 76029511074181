<template lang="html">
  <div class="kop">
    <img src="@/assets/kop.png" class="piekvanjut" alt="" />
    <h1>Fill in your details</h1>
  </div>
  <input
    v-model="name"
    class="form-control field no-focusborder mt-3"
    type="text"
    ref="inputfield"
    placeholder="Name"
    required
  />
  <input
    v-model="email"
    class="form-control field no-focusborder mt-3"
    type="email"
    ref="inputfield"
    placeholder="E-mail address"
    required
  />
  <span class="text-white mt-2">{{ validateEmail }}</span>
  <input
    v-model="phone"
    class="form-control field no-focusborder mt-3"
    type="tel"
    ref="inputfield"
    placeholder="Phone"
    required
  />
  <div class="mb-3 mt-3">
    By clicking send, I agree to the use of my data for commercial purposes.
  </div>
  <!-- <input
    v-model="company"
    class="form-control field no-focusborder"
    type="text"
    ref="inputfield"
    placeholder="Bedrijf"
    required
  />
  <input
    v-model="jobposition"
    class="form-control field no-focusborder"
    type="text"
    ref="inputfield"
    placeholder="Functie"
    required
  /> -->
  <!-- <select
    v-model="jobposition"
    class="form-select form-select-lg rounded-0 mt-3"
    aria-label="Default select example"
  >
    <option selected :value="null" disabled>Ik ben een ...</option>
    <option value="student">Ik ben student</option>
    <option value="professional">Ik ben professional</option>
  </select> -->
  <div class="d-grid gap-2 mt-3">
    <button
      type="button"
      class="btn btn-primary text-light btn-lg"
      :disabled="isDisabled == true"
      @click="postNewPlayer()"
    >
      Send
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PersonalDataScreen",
  data: () => ({
    name: null,
    email: null,
    company: null,
    jobposition: null,
    code: Math.floor(100000 + Math.random() * 900000),
    hiscore: 0,
  }),
  computed: {
    isDisabled() {
      if (this.name && this.email) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          return false;
        }
      }
      return true;
    },
    validateEmail() {
      let msg;
      if (this.email !== null) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          msg = "";
        } else {
          msg = "Please enter a valid email address";
        }
      }
      return msg;
    },
  },
  methods: {
    postNewPlayer() {
      axios
        .post(
          "https://db.kopvanjut.quantes.nl/api/content/item/game",
          {
            data: {
              name: this.name,
              email: this.email,
              phone: this.phone,
              // company: this.company,
              jobposition: this.jobposition,
              code: this.code,
              hiscore: 0,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": "API-eccbc13c530a209a1a99fb1e953776ffd7ff9778",
            },
          }
        )
        .then(() => {
          this.$store.commit("setCode", this.code);
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 40px;
  color: #333;
}
.field {
  /* text-transform: uppercase; */
  margin: auto;
  outline: none;
  border: none;
  width: 100%;
  font-size: 1.25rem;
  border-radius: 0px;
  text-align: center;
  color: #fff;
  background-color: #adc536;
}
.field::placeholder {
  color: #666;
}
.kop {
  text-align: center;
}
img.piekvanjut {
  max-width: 800px;
  width: 100%;
}
</style>
