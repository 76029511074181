import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
// import axios from "axios";
// import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    name: null,
    email: null,
    company: null,
    jobposition: null,
    code: null,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getName(state) {
      return state.name;
    },
    getEmail(state) {
      return state.email;
    },
    getCompany(state) {
      return state.company;
    },
    getJobposition(state) {
      return state.jobposition;
    },
    getCode(state) {
      return state.code;
    },
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setName(state, name) {
      state.name = name;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setCompany(state, company) {
      state.company = company;
    },
    setCode(state, code) {
      state.code = code;
    },
  },
  actions: {
    // storePlayer(state, data) {
    //   state.commit("setLoading", true);
    //
    // },
  },
  modules: {},
});
